<template>
  <el-dialog :visible.sync="visible" :title="form && form.id ? '编辑联系人信息' : '新增联系人信息'" append-to-body :close-on-click-modal="false" width="600px" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="100px" label-suffix=":" v-if="form">
      <div class="h">
        <el-form-item prop="name" label="联系人姓名">
          <el-input v-model="form.name" :maxlength="40" clearable style="width: 180px;" />
        </el-form-item>
        <el-form-item label="性别">
          <el-radio-group v-model="form.sex">
            <el-radio label="男" />
            <el-radio label="女" />
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="h">
        <el-form-item prop="mobilePhone" label="手机号码">
          <el-input v-model="form.mobilePhone" :maxlength="11" clearable style="width: 180px;" />
        </el-form-item>
        <el-form-item prop="officePhone" label="办公室电话">
          <el-input v-model="form.officePhone" :maxlength="25" clearable style="width: 180px;" />
        </el-form-item>
      </div>
      <el-form-item label="职务">
        <el-input v-model="form.title" :maxlength="25" clearable style="width: 180px;" />
      </el-form-item>
      <el-form-item label="主要联系人">
        <el-switch v-model="form.beMainLinker" />
      </el-form-item>
      <el-form-item label="默认联系人">
        <div class="h c">
          <el-switch v-model="form.isDefault" />
          <div class="padding-0-10 fc-g">提示:&nbsp;开启后，每次下单将默认使用该联系人</div>
        </div>
      </el-form-item>
    </el-form>

    <div slot="footer">
      <el-button type="text" @click="visible = false">取消</el-button>
      <el-button :loading="saving" type="primary" @click="doSave">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addContact as add, editContact as edit } from "@/api/receiving";

export default {
  data() {
    const checkPhones = (rule, val, callback) => {
      if (!this.form.mobilePhone && !this.form.officePhone) {
        callback("请选填手机号码或办公室电话");
      } else {
        callback();
      }
    };
    return {
      visible: false,
      form: null,
      saving: false,
      rules: {
        name: [{ required: true, message: "请填写姓名" }],
        mobilePhone: [
          { pattern: /^\d{11}$/gi, message: "请输入正确的手机号码" },
          { validator: checkPhones }
        ],
        officePhone: [{ validator: checkPhones }]
      }
    };
  },
  methods: {
    doSave() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              this.$notify({
                title: `保存联系人信息成功`,
                type: "success",
                duration: 2500
              });
              this.$parent.init();
              this.visible = false;
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    resetForm(form) {
      this.form = form || {
        beMainLinker: false,
        erpId: null,
        isDefault: false,
        name: "",
        mobilePhone: "",
        officePhone: "",
        sex: "男",
        title: ""
      };
      this.visible = true;
    }
  }
};
</script>